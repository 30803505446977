import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiInputBaseType = {
  defaultProps?: ComponentsProps['MuiInputBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
  variants?: ComponentsVariants['MuiInputBase'];
}

export const MuiInputBase:MuiInputBaseType = {
  styleOverrides: {
    root: {
      '&.MuiOutlinedInput-root': {
        borderRadius: '12px',
        input: {
          padding: '12px 20px'
        }
      }
    }
  }
}
