import { Box, Typography } from '@mui/material'
import { DefaultLayout } from '@shared/layout/DefaultLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Error404: React.FC = () => {
  const { t } = useTranslation()

  return (
    <DefaultLayout>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
      >
        <Typography sx={{
          fontSize: '28px',
          fontWeight: '600',
          lineHeight: '35px',
          textAlign: 'center',
          pb: '60px'
        }}
        >
          {t('common.404')}
        </Typography>
      </Box>
    </DefaultLayout>
  )
}
