import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiSliderType = {
  defaultProps?: ComponentsProps['MuiSlider'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSlider'];
  variants?: ComponentsVariants['MuiSlider'];
}

export const MuiSlider:MuiSliderType = {
  defaultProps: {
  },
  styleOverrides: {
    rail: {
      height: '4px',
      backgroundColor: '#E5E5E5'
    },
    valueLabel: {
      backgroundColor: '#E5E5E5',
      color: '#000000',
      borderRadius: '8px',
      '&:before': {
        width: '12px',
        height: '12px'
      }
    },
    track: {
      height: '4px'
    },
    thumb: {
      width: '30px',
      height: '30px',
      border: 'solid 2px white',
      boxShadow: 'none',
      '&:before': {
        boxShadow: 'none'
      }
    },
    mark: {
      display: 'none'
    }
  }
}
