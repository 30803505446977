import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'

export type MuiButtonType = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

export const MuiButton:MuiButtonType = {
  defaultProps: {
    variant: 'contained'
  },
  styleOverrides: {
    root: {
      padding: '12px 24px',
      borderRadius: '12px',
      textTransform: 'none',
      boxShadow: 'none',
      '&.Mui-disabled': {
        backgroundColor: '#E5E5E5'
      }
    }
  }
}
