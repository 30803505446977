import { Box, useTheme } from '@mui/material'
import React from 'react'
import { Star } from '@mui/icons-material'

import { EvaluationRankingItem } from './EvaluationRankingItem'

export type RankingItem = {
  name: string
  id: string
  seconds: number
  answer: string
  type: string
  difference?: number
  suffix?: string,
  hideDot?: boolean
}

type Props = {
  items: RankingItem[]
}

export const EvaluationRanking: React.FC<Props> = ({ items }) => {
  const { breakpoints } = useTheme()
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      {items.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            fontSize: '36px',
            lineHeight: '45px',
            py: 2.5,
            position: 'relative',
            ...(index < items.length - 1 && {
              borderBottom: '1px solid #898989'
            }),
            [breakpoints.down('md')]: {
              fontSize: '14px',
              lineHeight: '16px'
            }
          }}
        >
          {index === 0 && (
            <Star sx={{
              position: 'absolute',
              left: '-60px',
              height: '45px',
              width: '45px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#FFD700',
              [breakpoints.down('md')]: {
                display: 'none'
              }
            }}
            />
          )}
          <EvaluationRankingItem
            item={item}
            index={index + 1}
          />
        </Box>
      ))}
    </Box>
  )
}
