import React from 'react'
import { RouteObject } from 'react-router'
import { DefaultLayout } from '@shared/layout/DefaultLayout'
import { QuestionView } from 'src/feature/Question/views/QuestionView'
import { Error404 } from 'src/feature/Error/views/Error404'
import { EvaluationView } from 'src/feature/Evaluation/views/EvaluationView'

import { HomeView } from '../../feature/Home/views/HomeView'

export const routes: RouteObject[] = [{
  path: '/',
  element: <DefaultLayout />,
  children: [{
    index: true,
    element: <HomeView />
  }, {
    path: ':questionId',
    element: <QuestionView />
  }]
}, {
  path: '/evaluation',
  element: <DefaultLayout />,
  children: [{
    index: true,
    element: <EvaluationView />
  }, {
    path: ':id',
    element: <EvaluationView />
  }]
}, {
  path: '*',
  element: <Error404 />
}]
