import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const Logo:React.FC<SvgIconProps> = (props) => {
  const { sx, ...other } = props
  return (
    <SvgIcon
      width="67"
      height="67"
      viewBox="0 0 67 67"
      sx={{
        color: 'black',
        ...sx
      }}
      {...other}
    >
      <path fill="currentColor" d="M33.4236 67C29.6263 67.0071 25.8352 66.6924 22.0909 66.0593C20.0426 65.7007 18.0191 65.2119 16.0329 64.5959C12.8177 63.5871 9.89422 61.8149 7.51165 59.4307C5.12908 57.0464 3.35821 54.1207 2.35009 50.9033C1.67634 48.7329 1.17005 46.5139 0.835621 44.266C-0.627667 35.2179 -0.200946 25.9645 2.08895 17.0897L2.40229 16.0445C3.41257 12.8425 5.1788 9.93115 7.55141 7.55683C9.92402 5.18251 12.8332 3.41506 16.0329 2.40405C21.5079 0.775521 27.1896 -0.0518162 32.9014 -0.0522687C38.6672 -0.16366 44.4188 0.558023 49.9787 2.09048C53.5003 3.09688 56.7087 4.982 59.303 7.569C61.8973 10.156 63.7924 13.3601 64.8105 16.8806C66.2891 22.2411 67.0098 27.7825 66.9516 33.3432C67.0209 39.2831 66.2114 45.2006 64.5493 50.9033C63.534 54.111 61.7599 57.0262 59.3777 59.4009C56.9956 61.7757 54.0758 63.5399 50.8665 64.5437L49.8743 64.8573C44.5099 66.2979 38.9778 67.0184 33.4236 67ZM25.5377 61.3557C31.703 62.1266 37.9473 62.0034 44.0774 60.9899L28.4101 45.3112L25.5377 61.3557ZM6.26692 45.9384C6.26692 47.1404 6.84138 48.3424 7.15473 49.3877C7.90264 51.8551 9.24711 54.0998 11.069 55.923C12.8909 57.7462 15.134 59.0916 17.5996 59.8401C18.5589 60.1565 19.5353 60.4182 20.5242 60.624L23.501 43.1685L6.26692 45.9384ZM31.8568 41.5484L49.7698 59.5265C52.0749 58.7156 54.1586 57.3765 55.8543 55.6161C57.55 53.8556 58.8107 51.7227 59.5358 49.3877C60.7754 45.1906 61.4774 40.853 61.6248 36.4789L31.8568 41.5484ZM5.74465 22.943C4.72198 28.8641 4.56368 34.9024 5.27464 40.8689L20.942 38.1513L5.74465 22.943ZM34.9381 5.17394L29.7157 36.5312L61.7815 31.305C61.6599 26.9606 61.0289 22.6456 59.9014 18.4485C59.1132 15.7594 57.6623 13.3115 55.6821 11.3299C53.7019 9.34827 51.2559 7.89639 48.5687 7.10764C44.2092 5.91256 39.7184 5.26303 35.1992 5.17394H34.9381ZM7.15473 17.0897L24.6499 34.5452L29.8724 5.2262C25.6561 5.38042 21.4779 6.08314 17.4429 7.31669C15.1289 8.05628 13.0184 9.32422 11.2782 11.0203C9.53797 12.7165 8.21571 14.7943 7.41586 17.0897H7.15473Z"/>
    </SvgIcon>
  )
}
