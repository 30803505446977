import { MuiCssBaseline } from './components/baseline'
import { MuiButton } from './components/button'
import { MuiDialog } from './components/dialog'
import { MuiInputBase } from './components/input'
import { MuiSlider } from './components/slider'

export const components = {
  MuiCssBaseline,
  MuiButton,
  MuiSlider,
  MuiDialog,
  MuiInputBase
}
