import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material'
import { Question } from '@providers/QuestionProvider'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  question: Question
}

export const ChoiceComponent: React.FC<Props> = ({ question }) => {
  const { config } = question

  const { control, setValue, getValues } = useFormContext()

  const handleOnChange = (id: number) => {
    if (config.type === 'singlechoice') {
      setValue('answer', id, {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      const currentValues = getValues('answer') as number[]

      if (currentValues?.includes(id)) {
        setValue('answer', currentValues.filter((item) => item !== id), {
          shouldValidate: true,
          shouldDirty: true
        })
      } else {
        setValue('answer', [...(currentValues || []), id], {
          shouldValidate: true,
          shouldDirty: true
        })
      }
    }
  }

  return (
    <Box sx={{
      position: 'relative',
      py: 4
    }}
    >
      <Controller
        name="answer"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => config.type === 'singlechoice'
          ? (
            <FormControl>
              <RadioGroup
                value={value}
                onChange={(event) => onChange(event.target.value)}
              >
                {config.answers?.map((choice) => (
                  <FormControlLabel
                    key={`${question.id}-${choice.id}`}
                    value={choice.id}
                    control={<Radio />}
                    label={choice.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            )
          : (
            <FormGroup>
              {config.answers?.map((choice) => (
                <FormControlLabel
                  key={`${question.id}-${choice.id}`}
                  control={
                    <Checkbox
                      checked={value?.includes(choice.id)}
                      onChange={() => handleOnChange(choice.id)}
                    />}
                  label={choice.title}
                />
              ))}
            </FormGroup>
            )}
      />
    </Box>
  )
}
