import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiDialogType = {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
}

export const MuiDialog:MuiDialogType = {
  styleOverrides: {
    container: {
      width: '100%',
      '.MuiDialogContent-root': {
        padding: 0,
        marginTop: 0
      },
      '.MuiDialogTitle-root': {
        padding: 0,
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '8px'
      },
      '.MuiDialogActions-root': {
        padding: 0
      },
      '.MuiPaper-root': {
        padding: 24,
        borderRadius: 20
      }
    },
    paper: {
      margin: 0,
      width: '90%'
    }
  }
}
