import React from 'react'
import { Provider } from 'react-redux'
import { store } from '@services/store/store'
import { CssBaseline, ThemeProvider } from '@mui/material'
import '@services/i18n/i18n'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { theme } from '@services/theme/theme'

import { routes } from '../services/router/router'

import { QuestionsProvider } from './QuestionProvider'

const CoreProvider: React.FC<React.PropsWithChildren> = () => {
  const router = createBrowserRouter(routes)

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QuestionsProvider>
          <RouterProvider router={router} />
        </QuestionsProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default CoreProvider
