import { ThemeOptions } from '@mui/material/styles'
import {
  createTheme
} from '@mui/material'

import { palette } from './core/palette/palette'
import { components } from './components'
import { typography } from './core/typography/typography'

export const themeProperties: ThemeOptions = {
  palette, components, typography
}

export const theme = createTheme({
  ...themeProperties
})
