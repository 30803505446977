import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

type AppState = {
  name: string
  participantId: string
  answeredQuestions: string[]
}

const slice = createSlice({
  name: 'app',
  initialState: {
    name: '',
    participantId: Math.random().toString(36).substring(2, 8),
    answeredQuestions: []
  } as AppState,
  reducers: {
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload
    },
    setParticipantId: (state, { payload }: PayloadAction<string>) => {
      state.participantId = payload
    },
    addAnsweredQuestion: (state, { payload }: PayloadAction<string>) => {
      state.answeredQuestions.push(payload)
    },
    resetStore: (state) => {
      state.answeredQuestions = []
      state.name = ''
      state.participantId = uuidv4()
    }
  }
})

export const {
  setName, addAnsweredQuestion, resetStore, setParticipantId
} = slice.actions

export default slice.reducer
