import { Box, useTheme } from '@mui/material'
import { useQuestionsContext } from '@providers/QuestionProvider'
import { TabBar } from '@shared/components/TabBar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { EvaluationQuestion, EvaluationQuestionHandler } from '../components/EvaluationQuestion'

export const EvaluationView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const questionViewRef = React.useRef<EvaluationQuestionHandler>(null)

  const { questions } = useQuestionsContext()

  const { breakpoints } = useTheme()

  const [selectedQuestion, setSelectedQuestion] = React.useState<string>(id ?? '')

  const handleSelectedQuestionChange = React.useCallback((questionId: string) => {
    questionViewRef.current?.resetTimer()

    setSelectedQuestion(questionId)
    navigate(`/evaluation/${questionId}`)
  }, [])

  const mappedQuestions = React.useMemo(() => {
    return questions.filter(({ config }) => !config.hideEvaluation).map((question) => ({
      id: question.order.toString(),
      key: question.key,
      label: t('common.questionIndex', { index: question.order })
    }))
  }, [questions])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      px: 40,
      [breakpoints.down('md')]: {
        px: 1
      }
    }}
    >
      <Box sx={{
        flex: 1,
        position: 'relative',
        overflowY: 'auto',
        [breakpoints.down('md')]: {
          maxHeight: '90%',
          overflowY: 'auto',
          overflowX: 'hidden',
          mb: 2
        }
      }}
      >
        <EvaluationQuestion ref={questionViewRef} questionKey={selectedQuestion} />
      </Box>

      <Box flex={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 2
        }}
      >
        <TabBar
          selected={selectedQuestion}
          items={mappedQuestions}
          onChange={handleSelectedQuestionChange}
        />
      </Box>
    </Box>
  )
}
