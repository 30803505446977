import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const HeaderSvg:React.FC<SvgIconProps> = (props) => {
  const { sx, ...other } = props
  return (
    <SvgIcon
      width="329"
      height="45"
      viewBox="0 0 329 45"
      fill="none"
      sx={{
        color: 'black',
        ...sx
      }}
      {...other}
    >
      <path d="M318.63 31.32C318.63 29.836 319.036 28.044 319.848 25.944C318.084 26.084 316.642 26.266 315.522 26.49C314.43 26.714 313.842 26.826 313.758 26.826C313.114 26.826 312.61 26.588 312.246 26.112C311.91 25.636 311.742 25.202 311.742 24.81C311.742 24.39 311.756 24.124 311.784 24.012C311.952 23.116 313.128 21.674 315.312 19.686C317.496 17.67 319.792 15.85 322.2 14.226C324.608 12.602 326.19 11.79 326.946 11.79C327.422 11.79 327.8 11.944 328.08 12.252C328.388 12.56 328.542 12.966 328.542 13.47C328.542 13.946 328.276 14.618 327.744 15.486C327.212 16.326 326.75 17.11 326.358 17.838C325.994 18.566 325.686 19.21 325.434 19.77C324.93 20.918 324.524 21.912 324.216 22.752C325.168 22.696 325.966 22.668 326.61 22.668C327.254 22.668 327.8 22.696 328.248 22.752C327.884 24.572 327.646 25.524 327.534 25.608L322.998 25.692C322.27 27.008 321.682 29.206 321.234 32.286L318.798 32.664C318.686 32.3 318.63 31.852 318.63 31.32ZM320.898 23.046C321.542 21.31 322.858 18.594 324.846 14.898C323.166 15.766 321.206 17.18 318.966 19.14C316.726 21.1 315.2 22.724 314.388 24.012C317.244 23.508 319.414 23.186 320.898 23.046Z" fill="white"/>
      <path d="M298.887 17.544C300.063 16.172 301.617 14.968 303.549 13.932C305.481 12.868 307.091 12.336 308.379 12.336C309.695 12.336 310.731 12.686 311.487 13.386C312.243 14.086 312.621 15.08 312.621 16.368C312.621 17.656 312.103 18.832 311.067 19.896C310.031 20.932 307.959 22.248 304.851 23.844C301.743 25.412 298.859 27.344 296.199 29.64C300.035 29.052 303.605 28.758 306.909 28.758C307.665 28.758 308.267 28.786 308.715 28.842V31.488C308.099 31.6 307.679 31.656 307.455 31.656C307.259 31.628 306.923 31.628 306.447 31.656C305.971 31.684 305.047 31.74 303.675 31.824C302.303 31.88 300.889 31.978 299.433 32.118C298.005 32.258 297.025 32.37 296.493 32.454C295.989 32.51 295.681 32.538 295.569 32.538C295.121 32.538 294.729 32.258 294.393 31.698C294.057 31.11 293.889 30.55 293.889 30.018C294.113 29.15 295.219 27.89 297.207 26.238C299.223 24.586 301.799 22.948 304.935 21.324C305.859 20.848 306.713 20.232 307.497 19.476C308.281 18.72 308.673 18.006 308.673 17.334C308.673 16.046 307.987 15.402 306.615 15.402C304.795 15.402 302.457 16.578 299.601 18.93L298.887 17.544Z" fill="white"/>
      <path d="M289.389 12.336C290.957 12.336 292.203 12.784 293.127 13.68C294.079 14.576 294.555 15.878 294.555 17.586C294.555 19.266 293.841 21.324 292.413 23.76C290.985 26.196 289.123 28.324 286.827 30.144C284.559 31.936 282.389 32.832 280.317 32.832C276.929 32.832 275.235 30.942 275.235 27.162C275.235 24.95 275.935 22.696 277.335 20.4C278.763 18.076 280.583 16.158 282.795 14.646C285.035 13.106 287.233 12.336 289.389 12.336ZM286.533 28.296C287.933 26.952 289.053 25.342 289.893 23.466C290.761 21.59 291.195 19.952 291.195 18.552C291.195 17.124 290.873 16.046 290.229 15.318C289.585 14.562 288.703 14.184 287.583 14.184C285.623 14.184 283.663 15.458 281.703 18.006C279.771 20.526 278.805 23.06 278.805 25.608C278.805 26.98 279.127 28.114 279.771 29.01C280.415 29.878 281.353 30.312 282.585 30.312C283.845 30.312 285.161 29.64 286.533 28.296Z" fill="white"/>
      <path d="M262.547 17.544C263.723 16.172 265.277 14.968 267.209 13.932C269.141 12.868 270.751 12.336 272.039 12.336C273.355 12.336 274.391 12.686 275.147 13.386C275.903 14.086 276.281 15.08 276.281 16.368C276.281 17.656 275.763 18.832 274.727 19.896C273.691 20.932 271.619 22.248 268.511 23.844C265.403 25.412 262.519 27.344 259.859 29.64C263.695 29.052 267.265 28.758 270.569 28.758C271.325 28.758 271.927 28.786 272.375 28.842V31.488C271.759 31.6 271.339 31.656 271.115 31.656C270.919 31.628 270.583 31.628 270.107 31.656C269.631 31.684 268.707 31.74 267.335 31.824C265.963 31.88 264.549 31.978 263.093 32.118C261.665 32.258 260.685 32.37 260.153 32.454C259.649 32.51 259.341 32.538 259.229 32.538C258.781 32.538 258.389 32.258 258.053 31.698C257.717 31.11 257.549 30.55 257.549 30.018C257.773 29.15 258.879 27.89 260.867 26.238C262.883 24.586 265.459 22.948 268.595 21.324C269.519 20.848 270.373 20.232 271.157 19.476C271.941 18.72 272.333 18.006 272.333 17.334C272.333 16.046 271.647 15.402 270.275 15.402C268.455 15.402 266.117 16.578 263.261 18.93L262.547 17.544Z" fill="white"/>
      <path d="M245.019 27.918L245.901 28.59C245.285 29.822 244.431 30.858 243.339 31.698C242.275 32.538 241.309 32.958 240.441 32.958C239.573 32.958 238.887 32.706 238.383 32.202C237.879 31.698 237.627 31.068 237.627 30.312C237.627 29.556 238.103 28.24 239.055 26.364C240.035 24.46 240.525 23.06 240.525 22.164C240.525 21.772 240.357 21.422 240.021 21.114C239.713 20.778 239.335 20.61 238.887 20.61C237.459 20.61 236.017 21.702 234.561 23.886C233.105 26.042 231.481 29.164 229.689 33.252L227.169 32.37C227.701 29.122 228.457 25.958 229.437 22.878C230.445 19.77 231.369 17.544 232.209 16.2L233.931 16.746C233.147 18.734 232.153 22.164 230.949 27.036C234.813 20.428 238.369 17.124 241.617 17.124C242.317 17.124 242.891 17.362 243.339 17.838C243.815 18.314 244.053 18.874 244.053 19.518C244.053 20.162 243.969 20.75 243.801 21.282C243.633 21.786 243.101 22.99 242.205 24.894C241.337 26.77 240.903 27.946 240.903 28.422C240.903 28.898 241.043 29.276 241.323 29.556C241.603 29.808 241.869 29.934 242.121 29.934C242.373 29.934 242.583 29.892 242.751 29.808C242.919 29.724 243.101 29.598 243.297 29.43C243.521 29.262 243.717 29.108 243.885 28.968C244.053 28.828 244.249 28.646 244.473 28.422C244.725 28.17 244.907 28.002 245.019 27.918Z" fill="white"/>
      <path d="M224.671 18.132C225.371 18.132 225.945 18.37 226.393 18.846C226.841 19.322 227.065 19.938 227.065 20.694C227.065 22.206 226.197 23.802 224.461 25.482L223.453 24.684C223.845 23.816 224.041 23.186 224.041 22.794C224.041 22.038 223.621 21.66 222.781 21.66C221.941 21.66 221.143 22.192 220.387 23.256C219.631 24.32 218.735 25.972 217.699 28.212C216.691 30.424 215.921 31.992 215.389 32.916L212.995 31.866C214.535 28.366 215.417 26.308 215.641 25.692C216.397 23.508 216.775 20.736 216.775 17.376L219.127 16.746C219.043 18.566 218.917 19.98 218.749 20.988C218.581 21.968 218.175 23.578 217.531 25.818C220.359 20.694 222.739 18.132 224.671 18.132Z" fill="white"/>
      <path d="M209.095 28.044L210.061 28.8C209.193 30.004 208.003 31.012 206.491 31.824C204.979 32.608 203.509 33 202.081 33C200.653 33 199.505 32.58 198.637 31.74C197.769 30.9 197.335 29.794 197.335 28.422C197.335 25.846 198.693 23.312 201.409 20.82C204.153 18.328 206.925 17.082 209.725 17.082C211.573 17.082 212.497 17.81 212.497 19.266C212.497 20.778 211.517 22.304 209.557 23.844C207.625 25.384 204.895 26.784 201.367 28.044C201.619 28.688 202.011 29.192 202.543 29.556C203.075 29.92 203.621 30.102 204.181 30.102C205.385 30.102 207.023 29.416 209.095 28.044ZM201.157 26.784C203.425 26 205.371 25.02 206.995 23.844C208.619 22.668 209.431 21.59 209.431 20.61C209.431 20.246 209.291 19.924 209.011 19.644C208.731 19.364 208.409 19.224 208.045 19.224C206.729 19.224 205.301 20.05 203.761 21.702C202.249 23.326 201.381 25.02 201.157 26.784Z" fill="white"/>
      <path d="M195.879 27.54L196.761 28.38C195.165 31.236 193.331 32.664 191.259 32.664C190.419 32.664 189.761 32.44 189.285 31.992C188.837 31.516 188.613 30.9 188.613 30.144C188.613 29.36 189.075 28.03 189.999 26.154C190.923 24.278 191.413 22.85 191.469 21.87C191.497 21.422 191.357 21.058 191.049 20.778C190.769 20.498 190.377 20.358 189.873 20.358C189.369 20.358 188.851 20.484 188.319 20.736C187.815 20.96 187.311 21.338 186.807 21.87C186.331 22.402 185.883 22.92 185.463 23.424C185.071 23.928 184.623 24.642 184.119 25.566C183.643 26.49 183.237 27.26 182.901 27.876C182.313 29.024 181.473 30.746 180.381 33.042L177.861 32.16C177.861 32.076 178.323 30.494 179.247 27.414C180.171 24.334 180.633 22.5 180.633 21.912C180.633 21.324 180.493 20.904 180.213 20.652C179.933 20.372 179.583 20.232 179.163 20.232C177.903 20.232 176.153 21.8 173.913 24.936C171.701 28.072 170.287 30.746 169.671 32.958L167.277 32.16C167.417 30.676 168.075 28.016 169.251 24.18C170.427 20.316 171.421 17.684 172.233 16.284L174.123 16.956C173.255 18.888 172.653 20.526 172.317 21.87C171.981 23.186 171.687 24.6 171.435 26.112C173.843 22.668 175.859 20.26 177.483 18.888C179.107 17.488 180.577 16.788 181.893 16.788C183.321 16.788 184.035 17.502 184.035 18.93C184.035 19.49 183.783 20.596 183.279 22.248C182.803 23.9 182.425 25.202 182.145 26.154C186.009 19.938 189.467 16.83 192.519 16.83C193.275 16.83 193.877 17.068 194.325 17.544C194.773 17.992 194.997 18.678 194.997 19.602C194.997 20.498 194.465 21.996 193.401 24.096C192.365 26.196 191.847 27.526 191.847 28.086C191.847 28.618 191.959 29.01 192.183 29.262C192.407 29.514 192.687 29.64 193.023 29.64C193.751 29.64 194.703 28.94 195.879 27.54Z" fill="white"/>
      <path d="M165.988 27.54L166.87 28.38C165.274 31.236 163.44 32.664 161.368 32.664C160.528 32.664 159.87 32.44 159.394 31.992C158.946 31.516 158.722 30.9 158.722 30.144C158.722 29.36 159.184 28.03 160.108 26.154C161.032 24.278 161.522 22.85 161.578 21.87C161.606 21.422 161.466 21.058 161.158 20.778C160.878 20.498 160.486 20.358 159.982 20.358C159.478 20.358 158.96 20.484 158.428 20.736C157.924 20.96 157.42 21.338 156.916 21.87C156.44 22.402 155.992 22.92 155.572 23.424C155.18 23.928 154.732 24.642 154.228 25.566C153.752 26.49 153.346 27.26 153.01 27.876C152.422 29.024 151.582 30.746 150.49 33.042L147.97 32.16C147.97 32.076 148.432 30.494 149.356 27.414C150.28 24.334 150.742 22.5 150.742 21.912C150.742 21.324 150.602 20.904 150.322 20.652C150.042 20.372 149.692 20.232 149.272 20.232C148.012 20.232 146.262 21.8 144.022 24.936C141.81 28.072 140.396 30.746 139.78 32.958L137.386 32.16C137.526 30.676 138.184 28.016 139.36 24.18C140.536 20.316 141.53 17.684 142.342 16.284L144.232 16.956C143.364 18.888 142.762 20.526 142.426 21.87C142.09 23.186 141.796 24.6 141.544 26.112C143.952 22.668 145.968 20.26 147.592 18.888C149.216 17.488 150.686 16.788 152.002 16.788C153.43 16.788 154.144 17.502 154.144 18.93C154.144 19.49 153.892 20.596 153.388 22.248C152.912 23.9 152.534 25.202 152.254 26.154C156.118 19.938 159.576 16.83 162.628 16.83C163.384 16.83 163.986 17.068 164.434 17.544C164.882 17.992 165.106 18.678 165.106 19.602C165.106 20.498 164.574 21.996 163.51 24.096C162.474 26.196 161.956 27.526 161.956 28.086C161.956 28.618 162.068 29.01 162.292 29.262C162.516 29.514 162.796 29.64 163.132 29.64C163.86 29.64 164.812 28.94 165.988 27.54Z" fill="white"/>
      <path d="M135.304 15.192C133.736 15.192 132.952 14.562 132.952 13.302C132.952 12.602 133.148 12.014 133.54 11.538C133.932 11.062 134.436 10.824 135.052 10.824C135.668 10.824 136.172 11.006 136.564 11.37C136.956 11.734 137.152 12.21 137.152 12.798C137.152 13.386 136.984 13.932 136.648 14.436C136.312 14.94 135.864 15.192 135.304 15.192ZM134.758 27.582L135.598 28.296C134.926 29.584 134.03 30.69 132.91 31.614C131.818 32.51 130.824 32.958 129.928 32.958C128.36 32.958 127.576 31.866 127.576 29.682C127.576 26.966 128.696 23.424 130.936 19.056C131.44 18.104 131.734 17.544 131.818 17.376L133.624 18.468C133.036 19.532 132.308 21.226 131.44 23.55C130.6 25.874 130.18 27.372 130.18 28.044C130.18 28.688 130.32 29.15 130.6 29.43C130.88 29.71 131.216 29.85 131.608 29.85C132.364 29.85 133.414 29.094 134.758 27.582Z" fill="white"/>
      <path d="M122.305 28.968L123.187 29.682C122.235 31.166 121.409 32.244 120.709 32.916C120.037 33.56 119.281 33.882 118.441 33.882C117.601 33.882 116.915 33.546 116.383 32.874C115.851 32.202 115.585 31.362 115.585 30.354C115.585 28.394 116.873 24.18 119.449 17.712C122.025 11.216 124.671 5.50399 127.387 0.575989L129.235 1.58399C126.491 6.90399 124.013 12.196 121.801 17.46C119.589 22.696 118.483 26.462 118.483 28.758C118.483 30.074 118.973 30.732 119.953 30.732C120.737 30.732 121.521 30.144 122.305 28.968Z" fill="white"/>
      <path d="M110.847 16.494L109.503 21.576L114.963 20.946L113.745 22.92L109.041 23.55C108.677 25.062 108.061 27.344 107.193 30.396C105.317 37.004 104.379 41.19 104.379 42.954L101.691 44.34C101.691 41.932 102.727 37.284 104.799 30.396C105.863 26.952 106.521 24.74 106.773 23.76C105.793 24.012 104.897 24.138 104.085 24.138C102.489 24.138 101.691 23.508 101.691 22.248C101.691 21.548 102.223 20.708 103.287 19.728C104.827 18.3 106.661 17.04 108.789 15.948C110.441 10.32 111.631 6.792 112.359 5.364C113.339 3.432 114.235 2.2 115.047 1.668C116.251 0.911997 117.497 0.533997 118.785 0.533997C120.073 0.533997 120.983 0.897997 121.515 1.626C122.047 2.354 122.285 3.208 122.229 4.188C122.089 7.716 118.295 11.818 110.847 16.494ZM117.567 4.104C115.999 4.104 114.837 4.86 114.081 6.372C113.493 7.548 112.975 8.962 112.527 10.614C112.079 12.266 111.757 13.4 111.561 14.016C113.101 13.064 114.753 11.776 116.517 10.152C118.281 8.528 119.191 7.114 119.247 5.91C119.303 4.706 118.743 4.104 117.567 4.104ZM107.361 21.786L108.285 18.09C107.221 18.538 106.381 19 105.765 19.476C105.177 19.952 104.883 20.358 104.883 20.694C104.883 21.422 105.709 21.786 107.361 21.786Z" fill="white"/>
      <path d="M100.395 27.918L101.277 28.59C100.661 29.822 99.8067 30.858 98.7147 31.698C97.6507 32.538 96.6847 32.958 95.8167 32.958C94.9487 32.958 94.2627 32.706 93.7587 32.202C93.2547 31.698 93.0027 31.068 93.0027 30.312C93.0027 29.556 93.4787 28.24 94.4307 26.364C95.4107 24.46 95.9007 23.06 95.9007 22.164C95.9007 21.772 95.7327 21.422 95.3967 21.114C95.0887 20.778 94.7107 20.61 94.2627 20.61C92.8347 20.61 91.3927 21.702 89.9367 23.886C88.4807 26.042 86.8567 29.164 85.0647 33.252L82.5447 32.37C83.0767 29.122 83.8327 25.958 84.8127 22.878C85.8207 19.77 86.7447 17.544 87.5847 16.2L89.3067 16.746C88.5227 18.734 87.5287 22.164 86.3247 27.036C90.1887 20.428 93.7447 17.124 96.9927 17.124C97.6927 17.124 98.2667 17.362 98.7147 17.838C99.1907 18.314 99.4287 18.874 99.4287 19.518C99.4287 20.162 99.3447 20.75 99.1767 21.282C99.0087 21.786 98.4767 22.99 97.5807 24.894C96.7127 26.77 96.2787 27.946 96.2787 28.422C96.2787 28.898 96.4187 29.276 96.6987 29.556C96.9787 29.808 97.2447 29.934 97.4967 29.934C97.7487 29.934 97.9587 29.892 98.1267 29.808C98.2947 29.724 98.4767 29.598 98.6727 29.43C98.8967 29.262 99.0927 29.108 99.2607 28.968C99.4287 28.828 99.6247 28.646 99.8487 28.422C100.101 28.17 100.283 28.002 100.395 27.918Z" fill="white"/>
      <path d="M78.9805 28.044L79.9465 28.8C79.0785 30.004 77.8885 31.012 76.3765 31.824C74.8645 32.608 73.3945 33 71.9665 33C70.5385 33 69.3905 32.58 68.5225 31.74C67.6545 30.9 67.2205 29.794 67.2205 28.422C67.2205 25.846 68.5785 23.312 71.2945 20.82C74.0385 18.328 76.8105 17.082 79.6105 17.082C81.4585 17.082 82.3825 17.81 82.3825 19.266C82.3825 20.778 81.4025 22.304 79.4425 23.844C77.5105 25.384 74.7805 26.784 71.2525 28.044C71.5045 28.688 71.8965 29.192 72.4285 29.556C72.9605 29.92 73.5065 30.102 74.0665 30.102C75.2705 30.102 76.9085 29.416 78.9805 28.044ZM71.0425 26.784C73.3105 26 75.2565 25.02 76.8805 23.844C78.5045 22.668 79.3165 21.59 79.3165 20.61C79.3165 20.246 79.1765 19.924 78.8965 19.644C78.6165 19.364 78.2945 19.224 77.9305 19.224C76.6145 19.224 75.1865 20.05 73.6465 21.702C72.1345 23.326 71.2665 25.02 71.0425 26.784Z" fill="white"/>
      <path d="M67.4702 16.578L67.0922 18.216C66.6162 18.16 66.0002 18.132 65.2442 18.132C62.4442 18.132 61.0442 19.056 61.0442 20.904C61.0442 21.296 61.6042 22.444 62.7242 24.348C63.8722 26.252 64.4462 27.526 64.4462 28.17C64.4462 29.682 63.9282 30.872 62.8922 31.74C61.8842 32.58 60.5262 33 58.8182 33C56.4662 33 54.5762 31.698 53.1482 29.094L54.7442 27.96C55.1362 28.268 55.5562 28.646 56.0042 29.094C56.4522 29.514 56.7882 29.808 57.0122 29.976C57.5442 30.452 58.1322 30.69 58.7762 30.69C59.4202 30.69 59.9802 30.466 60.4562 30.018C60.9322 29.542 61.1702 28.968 61.1702 28.296C61.1702 27.596 60.5542 26.308 59.3222 24.432C58.1182 22.556 57.5162 21.24 57.5162 20.484C57.5162 19.112 58.0902 18.062 59.2382 17.334C60.4142 16.606 61.9402 16.242 63.8162 16.242C65.7202 16.242 66.9382 16.354 67.4702 16.578Z" fill="white"/>
      <path d="M50.9455 28.044L51.9115 28.8C51.0435 30.004 49.8535 31.012 48.3415 31.824C46.8295 32.608 45.3595 33 43.9315 33C42.5035 33 41.3555 32.58 40.4875 31.74C39.6195 30.9 39.1855 29.794 39.1855 28.422C39.1855 25.846 40.5435 23.312 43.2595 20.82C46.0035 18.328 48.7756 17.082 51.5756 17.082C53.4236 17.082 54.3475 17.81 54.3475 19.266C54.3475 20.778 53.3676 22.304 51.4076 23.844C49.4755 25.384 46.7455 26.784 43.2175 28.044C43.4695 28.688 43.8615 29.192 44.3935 29.556C44.9255 29.92 45.4715 30.102 46.0315 30.102C47.2355 30.102 48.8735 29.416 50.9455 28.044ZM43.0075 26.784C45.2755 26 47.2216 25.02 48.8456 23.844C50.4696 22.668 51.2815 21.59 51.2815 20.61C51.2815 20.246 51.1415 19.924 50.8615 19.644C50.5815 19.364 50.2596 19.224 49.8955 19.224C48.5795 19.224 47.1515 20.05 45.6115 21.702C44.0995 23.326 43.2315 25.02 43.0075 26.784Z" fill="white"/>
      <path d="M36.9353 15.192C35.3673 15.192 34.5833 14.562 34.5833 13.302C34.5833 12.602 34.7793 12.014 35.1713 11.538C35.5633 11.062 36.0673 10.824 36.6833 10.824C37.2993 10.824 37.8033 11.006 38.1953 11.37C38.5873 11.734 38.7833 12.21 38.7833 12.798C38.7833 13.386 38.6153 13.932 38.2793 14.436C37.9433 14.94 37.4953 15.192 36.9353 15.192ZM36.3893 27.582L37.2293 28.296C36.5573 29.584 35.6613 30.69 34.5413 31.614C33.4493 32.51 32.4553 32.958 31.5593 32.958C29.9913 32.958 29.2073 31.866 29.2073 29.682C29.2073 26.966 30.3273 23.424 32.5673 19.056C33.0713 18.104 33.3653 17.544 33.4493 17.376L35.2553 18.468C34.6673 19.532 33.9393 21.226 33.0713 23.55C32.2313 25.874 31.8113 27.372 31.8113 28.044C31.8113 28.688 31.9513 29.15 32.2313 29.43C32.5113 29.71 32.8473 29.85 33.2393 29.85C33.9953 29.85 35.0453 29.094 36.3893 27.582Z" fill="white"/>
      <path d="M29.198 6.96003L32.306 6.54003C32.474 7.29603 32.558 8.12203 32.558 9.01803C32.558 11.258 32.026 13.806 30.962 16.662C29.926 19.518 28.666 22.122 27.182 24.474C25.698 26.798 24.116 28.772 22.436 30.396C20.756 31.992 19.342 32.79 18.194 32.79C15.73 32.79 14.498 30.858 14.498 26.994C14.498 25.146 15.156 21.31 16.472 15.486C13.224 20.078 10.228 24.11 7.48401 27.582C4.74001 31.026 3.07401 32.748 2.48601 32.748C1.92601 32.748 1.40801 32.524 0.93201 32.076C0.48401 31.628 0.26001 31.166 0.26001 30.69C0.26001 30.214 0.34401 29.738 0.51201 29.262C1.71601 25.65 2.50001 22.262 2.86401 19.098C3.25601 15.934 3.45201 13.106 3.45201 10.614C3.45201 8.09403 3.43801 6.54003 3.41001 5.95203H6.68601C6.85401 6.84803 6.93801 8.24803 6.93801 10.152C6.93801 12.028 6.70001 14.66 6.22401 18.048C5.77601 21.408 5.21601 24.53 4.54401 27.414C6.64401 24.726 10.984 18.818 17.564 9.69003C18.32 8.59803 18.922 7.88403 19.37 7.54803C19.846 7.18403 20.406 7.00203 21.05 7.00203C21.722 7.00203 22.058 7.32403 22.058 7.96803C22.058 8.22003 21.708 9.42403 21.008 11.58C20.308 13.736 19.636 16.41 18.992 19.602C18.348 22.794 18.026 25.216 18.026 26.868C18.026 28.52 18.446 29.346 19.286 29.346C19.874 29.346 20.602 28.926 21.47 28.086C23.626 26.014 25.46 23.172 26.972 19.56C28.484 15.92 29.24 12.182 29.24 8.34603C29.24 7.73003 29.226 7.26803 29.198 6.96003Z" fill="white"/>
    </SvgIcon>
  )
}
