export const convertBytesToHumanReadable = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())

  return (Math.round(bytes / Math.pow(1024, i) * 100) / 100) + ' ' + sizes[i]
}

export const lowerFirstLetter = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const dotToNumber = (value: number | string, ignore = false) => {
  const formatted = typeof value === 'number' ? value.toString() : value

  return ignore ? formatted : formatted.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
