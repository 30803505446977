import { Button, ButtonGroup } from '@mui/material'
import React from 'react'

export type TabItem = {
  id: string
  key: string
  label: string
}

type Props = {
  selected: string
  items: TabItem[]
  onChange: (key: string) => void
}

export const TabBar: React.FC<Props> = ({ items, selected, onChange }) => {
  return (
    <ButtonGroup variant="outlined">
      {items.map((item) => (
        <Button
          key={item.key}
          onClick={() => onChange(item.id)}
          sx={{
            '&.Mui-disabled': {
              pointerEvents: 'all',
              '&:hover': {
                backgroundColor: 'inherit'
              }
            },
            ...((selected === item.id) && {
              '&.MuiButton-outlined': {
                backgroundColor: 'primary.main',
                borderColor: 'primary.main',
                color: 'white'
              }
            })
          }}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}
