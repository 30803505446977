import { Box, Button, LinearProgress, Typography, useTheme } from '@mui/material'
import { useQuestionsContext } from '@providers/QuestionProvider'
import axios from 'axios'
import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Trans, useTranslation } from 'react-i18next'
import { dotToNumber } from '@utils/format'

import { EvaluationRanking, RankingItem } from './EvaluationRanking'

type Tracking = {
  answer: string
  createdAt: Date
  name: string
  participantId: string
  time: number
}

type TrackingResponse = {
  id: number,
  attributes: Tracking
}

type Props = {
  questionKey: string
}

export type EvaluationQuestionHandler = {
  resetTimer: () => void
}

type EvaluationQuestionRenderFn = React.ForwardRefRenderFunction<EvaluationQuestionHandler, Props>

const EvaluationQuestionContent:EvaluationQuestionRenderFn = ({ questionKey }, ref) => {
  const { t } = useTranslation()
  const [ranking, setRanking] = React.useState<RankingItem[]>([])
  const [showRanking, setShowRanking] = React.useState<boolean>(false)
  const [showLoader, setShowLoader] = React.useState<boolean>(false)
  const [loadingProgress, setLoadingProgress] = React.useState<number>(0)

  React.useImperativeHandle(ref, () => ({
    resetTimer: () => {
      setShowLoader(false)
      setShowRanking(false)
      setLoadingProgress(0)
    }
  }), [])

  const refLoadingInterval = React.useRef<any>()

  const { questions, fetchQuestions } = useQuestionsContext()

  const { breakpoints } = useTheme()

  const currentQuestion = React.useMemo(() => {
    return questions.find((question) => question.order.toString() === questionKey)
  }, [questions, questionKey])

  const getSliderRankings = (trackings: TrackingResponse[]) => {
    if (!currentQuestion) {
      return []
    }

    return trackings.map((tracking) => {
      return {
        name: tracking.attributes.name,
        id: tracking.attributes.participantId,
        answer: tracking.attributes.answer,
        difference: parseInt(tracking.attributes.answer) - parseInt(currentQuestion.answer),
        seconds: tracking.attributes.time,
        suffix: currentQuestion.config.suffixValue || '',
        hideDot: !!currentQuestion.config.hideDot,
        type: currentQuestion.config.type
      }
    }).sort((a, b) => {
      const diffComparison = Math.abs(a.difference) - Math.abs(b.difference)
      if (diffComparison !== 0) {
        return diffComparison
      }
      return a.seconds - b.seconds
    })
  }

  const getChoiceRankings = (trackings: TrackingResponse[]) => {
    if (!currentQuestion) {
      return []
    }

    return trackings.filter((tracking) => {
      if (currentQuestion?.config?.type === 'singlechoice') {
        return parseInt(tracking.attributes.answer, 10) === parseInt(currentQuestion.answer, 10)
      }

      const correctAnswers = JSON.parse(currentQuestion.answer || '[]')

      return tracking.attributes.answer?.split(',').every((answer) => correctAnswers?.includes(parseInt(answer, 10)))
    }).map((filtered) => {
      return {
        name: filtered.attributes.name,
        id: filtered.attributes.participantId,
        answer: filtered.attributes.answer,
        seconds: filtered.attributes.time,
        type: currentQuestion.config.type
      }
    })
  }

  const calculateDiagramData = React.useCallback(async () => {
    if (!currentQuestion) return

    try {
      const { data: trackings } = await axios.get(
        `${import.meta.env.REACT_APP_API_ENDPOINT}/trackings?filters[question][id][$eq]=${currentQuestion.id}`, {
          headers: {
            Authorization: `Bearer ${import.meta.env.REACT_APP_API_TOKEN}`
          }
        }) as { data: { data: TrackingResponse[] } }

      let mappedTrackings = [] as RankingItem[]

      if (currentQuestion.config.type === 'slider') {
        mappedTrackings = getSliderRankings(trackings.data)
      } else if (currentQuestion.config.type === 'singlechoice' || currentQuestion.config.type === 'multiplechoice') {
        mappedTrackings = getChoiceRankings(trackings.data)
      }

      setRanking(mappedTrackings.slice(0, 10))
    } catch (error) {
      console.log(error)
    }
  }, [currentQuestion])

  React.useEffect(() => {
    calculateDiagramData()
  }, [currentQuestion])

  const handleShowRanking = React.useCallback(() => {
    setShowLoader(true)
    fetchQuestions()

    refLoadingInterval.current = setInterval(() => {
      setLoadingProgress((prev) => {
        if (prev >= 100) {
          clearInterval(refLoadingInterval.current)
          setTimeout(() => {
            setShowLoader(false)
            setShowRanking(true)
          }, 200)

          return 100
        }

        const newValue = prev + Math.floor((Math.random() * 5) + 5)

        return Math.max(0, Math.min(newValue, 100))
      })
    }, Math.floor(Math.random() * 100) + 100)
  }, [])

  const questionAnswer = React.useMemo(() => {
    if (currentQuestion?.config.type === 'singlechoice') {
      return currentQuestion.config.answers?.find((answer) => answer.id === parseInt(currentQuestion.answer, 10))?.title
    } else if (currentQuestion?.config.type === 'multiplechoice') {
      const answers = JSON.parse(currentQuestion.answer || '[]')

      return answers.map((answer: any) => {
        return currentQuestion.config.answers?.find((item) => item.id === answer)?.title
      }).join(', ')
    } else if (currentQuestion?.config.type === 'slider') {
      return (dotToNumber(currentQuestion.answer, !!currentQuestion.config.hideDot) + (currentQuestion.config.suffixValue || ''))
    }

    return currentQuestion?.answer
  }, [currentQuestion])

  return currentQuestion
    ? (
      <Box sx={{
        fontSize: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'

      }}
      >
        <Box flex={0}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            mt: 2,
            [breakpoints.down('md')]: {
              mt: 0
            }
          }}
        >
          <Box flex={1}
            sx={{
              fontSize: '42px',
              lineHeight: '50px',
              fontWeight: '500',
              [breakpoints.down('md')]: {
                fontSize: '18px',
                lineHeight: '22px'
              }
            }}
          >
            <Typography sx={{
              fontSize: '32px',
              fontWeight: 500,
              lineHeight: '40px',
              [breakpoints.down('md')]: {
                fontSize: '18px',
                lineHeight: '22px'
              }
            }}
            >
              {t('common.questionIndex', { index: (questions.findIndex(({ key }) => currentQuestion?.key === key) || 0) + 1 })}
            </Typography>

            <Box sx={{
              py: 3,
              fontWeight: 600,
              fontSize: '48px',
              lineHeight: '60px',
              [breakpoints.down('md')]: {
                fontSize: '20px',
                lineHeight: '24px'
              },
              '>p': {
                margin: 0
              }
            }}
            >

              <ReactMarkdown>
                {currentQuestion.question}
              </ReactMarkdown>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{
            opacity: showRanking ? 1 : 0
          }}
          >
            <Typography sx={{
              fontSize: '32px',
              fontWeight: 500,
              lineHeight: '40px',
              mb: 4,
              [breakpoints.down('md')]: {
                fontSize: '18px',
                lineHeight: '22px'
              }
            }}
            >
              <Trans
                i18nKey="common.correctAnswer"
                values={{
                  answer: questionAnswer
                }}
                components={{ b: <b /> }}
              />
            </Typography>
            <EvaluationRanking items={ranking} />
          </Box>
        </Box>

        {!showRanking &&
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          >
            {!showLoader
              ? (
                <Button size='large' onClick={() => handleShowRanking()}>
                  {t('common.showAnswer')}
                </Button>
                )
              : (
                <LinearProgress
                  sx={{
                    height: '20px',
                    width: '450px'
                  }}
                  variant="determinate"
                  value={loadingProgress}
                />
                )}
          </Box>
        }
      </Box>
      )
    : null
}

export const EvaluationQuestion = React.forwardRef(EvaluationQuestionContent)
