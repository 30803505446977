import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { setName } from '@services/store/slices/app'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

export const NameDialog: React.FC = () => {
  const { t } = useTranslation()

  const { name } = useAppSelector(({ app }) => app)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const [nameInput, setNameInput] = React.useState('')

  const handleConfirm = React.useCallback(() => {
    dispatch(setName(nameInput))
  }, [nameInput])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(event.target.value)
  }

  return (
    <Dialog open={!name && !pathname.includes('evaluation')}>
      <DialogTitle>{t('common.enterName')}</DialogTitle>
      <DialogContent>
        <Typography>{t('common.enterNameDescription')}</Typography>

        <Box
          sx={{
            my: 2
          }}
        >
          <TextField
            fullWidth
            inputProps={{ maxLength: 20 }}
            value={nameInput}
            onChange={handleInputChange}
          ></TextField>
          <Box sx={{ textAlign: 'right' }}>
            <Typography>{t('common.availableLetters', { value: nameInput.length, letters: 20 })}</Typography>
          </Box>
        </Box>

        <Button
          disabled={nameInput.length < 2}
          variant="contained"
          onClick={handleConfirm}
          fullWidth
        >
          {t('common.letsGo')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
