import { Box, Button, Typography } from '@mui/material'
import { useQuestionsContext } from '@providers/QuestionProvider'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useNavigate, useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { addAnsweredQuestion } from '@services/store/slices/app'

import { SliderComponent } from '../components/SliderComponent'
import { ChoiceComponent } from '../components/ChoiceComponent'

type AnswerInput = {
  answer: number | string
  questionId: string
  name: string
  participantId: string
}

const QuestionViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { questionId } = useParams<{ questionId: string }>()

  const dispatch = useAppDispatch()

  const { participantId, name } = useAppSelector(({ app }) => app)

  const [timestamp, setTimestamp] = React.useState<number>()
  const [isLoading, setIsLoading] = React.useState(false)

  const { questions } = useQuestionsContext()
  const { formState: { isValid, isDirty }, handleSubmit } = useFormContext()

  React.useEffect(() => {
    setTimestamp(Date.now())
  }, [])

  const currentQuestion = React.useMemo(() => {
    return questions.find(question => question.key === questionId)
  }, [questionId, questions])

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    // Date.now() - timestamp! to seconds
    const seconds = (Date.now() - timestamp!) / 1000

    try {
      await axios.post(`${import.meta.env.REACT_APP_API_ENDPOINT}/trackings`, {
        headers: {
          Authorization: `Bearer ${import.meta.env.REACT_APP_API_TOKEN}`
        },
        data: {
          participantId,
          name,
          questionId,
          answer: typeof data.answer !== 'string' ? data.answer.toString() : data.answer,
          time: seconds,
          question: questions.find(question => question.key === questionId)?.id
        }
      })

      dispatch(addAnsweredQuestion(questionId as string))
      navigate('/')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  })

  const handleNavigateBack = () => {
    navigate('/')
  }

  const QuestionComponent = React.useMemo(() => {
    switch (currentQuestion?.config.type) {
      case 'slider':
        return SliderComponent
      case 'singlechoice':
        return ChoiceComponent
      case 'multiplechoice':
        return ChoiceComponent
    }
  }, [currentQuestion])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      {currentQuestion
        ? (<>
          <Box flex={1}>
            <Typography>
              {t('common.questionDescription')}
            </Typography>

            <Typography sx={{
              fontSize: '28px',
              fontWeight: '600',
              lineHeight: '35px',
              mt: 3
            }}
            >
              {t('common.questionIndex', { index: (questions.findIndex(({ key }) => currentQuestion?.key === key) || 0) + 1 })}
            </Typography>
            {currentQuestion?.config.showQuestion && (
            <ReactMarkdown>
              {currentQuestion?.question ?? ''}
            </ReactMarkdown>
            )}
            <Box>
              {QuestionComponent && <QuestionComponent question={currentQuestion} />}
            </Box>
          </Box>
          <Box flex={0}>
            <LoadingButton
              variant='contained'
              fullWidth
              loading={isLoading}
              disabled={(!isValid || !isDirty)}
              onClick={onSubmit}
            >
              {t('common.sendAnswer')}
            </LoadingButton>
          </Box>
        </>)
        : <>
          <Box flex={1}>
            <Typography sx={{
              fontSize: '28px',
              fontWeight: '600',
              lineHeight: '35px',
              mt: 3,
              mb: 6,
              textAlign: 'center'
            }}
            >
              {t('common.noQuestions')}
            </Typography>
          </Box>
          <Box flex={0}>
            <Button
              variant='contained'
              fullWidth
              onClick={handleNavigateBack}
            >
              {t('common.back')}
            </Button>
          </Box>
        </>
        }
    </Box>
  )
}

export const QuestionView: React.FC = () => {
  const formData = useForm<AnswerInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <QuestionViewContent />
    </FormProvider>
  )
}
