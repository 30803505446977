import { Delete } from '@mui/icons-material'
import { Box, IconButton, useTheme } from '@mui/material'
import { HeaderSvg } from '@shared/components/HeaderSvg'
import { Logo } from '@shared/components/Logo'
import { NameDialog } from '@shared/components/NameDialog'
import React from 'react'
import { Outlet, useNavigate } from 'react-router'

export const DefaultLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  const { breakpoints } = useTheme()

  const handleReset = () => {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      backgroundColor: 'white',

      position: 'relative'
    }}
    >
      {process.env.NODE_ENV === 'development' && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }}
        >
          <IconButton
            onClick={handleReset}
          >
            <Delete />
          </IconButton>
        </Box>
      )}
      <Box sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transform: 'translate(20%, 20%)'
      }}
      >
        <Logo sx={{
          height: '600px',
          width: '600px',
          color: '#F4F4F4',
          [breakpoints.down('md')]: {
            height: '288px',
            width: '288px'
          }
        }}
        />
      </Box>
      <Box sx={{
        flex: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A2B78B',
        pb: 1.5,
        pt: 4,
        px: 4
      }}
      >
        <Box sx={{
          cursor: 'pointer'
        }}
          onClick={() => navigate('/')}
        >
          <HeaderSvg
            sx={{
              width: '100%',
              height: '35px'
            }}
          />
        </Box>
      </Box>

      <Box
        flex={1}
        sx={{
          width: '100%',
          py: 5,
          px: 3,
          overflow: 'hidden'
        }}
      >
        {children || (<Outlet />)}
      </Box>
      <NameDialog />
    </Box>
  )
}
