import { Grid } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { dotToNumber } from '@utils/format'

import { RankingItem } from './EvaluationRanking'

type Props = {
  item: RankingItem,
  index: number
}

export const EvaluationRankingItem: React.FC<Props> = ({ item, index }) => {
  const { t } = useTranslation()

  const formattedDifference = React.useMemo(() => {
    let prefix = ''

    if ((item?.difference || 0) > 0) {
      prefix = '+'
    } else if (item?.difference === 0) {
      prefix = '±'
    }

    return `${prefix}${dotToNumber(item?.difference || 0, !!item.hideDot)}`
  }, [item])

  return (
    <Grid container spacing={[1, 2]}>
      <Grid item
        xs={3}
        md={2}
        sx={{
          fontWeight: 'bold'
        }}
      >
        {t('common.ranking', { index })}
      </Grid>
      <Grid item
        xs={3}
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        {item.name}
      </Grid>
      <Grid item xs={3} md={5}>
        {item.type === 'slider'
          ? (
            <Trans
              i18nKey="common.difference"
              values={{
                difference: formattedDifference,
                answer: dotToNumber(item.answer, !!item.hideDot),
                suffix: ''
              }}
              components={{
                difference: <span style={{
                  color: '#898989'
                }}
                />
              }}
            />
            )
          : t('common.seconds', { seconds: item.seconds })}
      </Grid>
      <Grid item
        xs={3}
        md={2}
        sx={{
          textAlign: 'right',
          color: '#898989'
        }}
      >
        {t('common.id', { id: item.id })}
      </Grid>
    </Grid>
  )
}
