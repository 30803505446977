import axios from 'axios'
import React from 'react'

type Config = {
  maxValue: number
  minValue: number
  stepSize: number
  dependsOn: number
  startValue: number
  suffixValue: string
  hideDot?: boolean
  answers?: {
    id: number
    title: string
    correct: boolean
  }[],
  type: 'slider' | 'singlechoice' | 'multiplechoice'
  [key: string]: any
}

export type Question = {
  id: string
  key: string
  question: string
  answer: string
  order: number
  config: Config
}

export type QuestionsProviderContextType = {
  questions: Question[],
  selectedQuestion: string,
  updateSelectedQuestion: (questionKey: string) => void,
  fetchQuestions: () => Promise<void>
}

const QuestionsProviderContext = React.createContext<QuestionsProviderContextType>(
  {} as any
)

export const QuestionsProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const [questions, setQuestions] = React.useState<Question[]>([])
  const [selectedQuestion, setSelectedQuestion] = React.useState<string>('')

  const updateSelectedQuestion = React.useCallback((questionKey: string) => {
    setSelectedQuestion(questionKey)
  }, [])

  const fetchQuestions = React.useCallback(async () => {
    let fetchedQuestions = [] as Question[]

    try {
      const result = await axios.get(`${import.meta.env.REACT_APP_API_ENDPOINT}/questions`, {
        headers: {
          Authorization: `Bearer ${import.meta.env.REACT_APP_API_TOKEN}`
        }
      })

      fetchedQuestions = result.data.data.map((question: any) => ({
        id: question.id,
        key: question.attributes.key,
        question: question.attributes.question,
        order: question.attributes.order,
        answer: question.attributes.answer,
        config: question.attributes.config || {}
      })).sort((a: Question, b: Question) => a.order - b.order) as Question[]
    } catch (error) {
      console.log(error)
    } finally {
      setQuestions(fetchedQuestions)
    }
  }, [])

  React.useEffect(() => {
    fetchQuestions()
  }, [])

  const value = React.useMemo<QuestionsProviderContextType>(() => ({
    questions,
    selectedQuestion,
    updateSelectedQuestion,
    fetchQuestions
  }), [questions, fetchQuestions, selectedQuestion])

  return (
    <>
      <QuestionsProviderContext.Provider value={value}>
        {children}
      </QuestionsProviderContext.Provider>
    </>
  )
}

export const useQuestionsContext = () => React.useContext(QuestionsProviderContext)
