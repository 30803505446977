import { Box, Slider, Typography } from '@mui/material'
import { Question } from '@providers/QuestionProvider'
import { dotToNumber } from '@utils/format'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  question: Question
}

export const SliderComponent: React.FC<Props> = ({ question }) => {
  const { config } = question

  const { control } = useFormContext()

  const sliderMarksCount = React.useMemo(() => Math.floor(((config.maxValue || 0) - (config.minValue || 0)) / (config.stepSize || 0) + 1), [config])

  const sliderMarks = React.useMemo(() => Array.from(new Array(sliderMarksCount)).map((step, index) => ({
    value: (config.minValue || 0) + (index * (config.stepSize || 0)),
    label: `${((config.minValue || 0) + (index * (config.stepSize || 0)))}${config.suffixValue || ''}`
  })), [sliderMarksCount])

  return (
    <Box sx={{
      position: 'relative',
      py: 4
    }}
    >
      <Controller
        name="answer"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box sx={{
              backgroundColor: '#E5E5E5',
              borderRadius: '8px',
              padding: '8px',
              width: 'auto',
              display: 'inline-block',
              mb: 2
            }}
            >
              <Typography>
                {`${dotToNumber(value ?? (config.startValue || config.minValue || 0), !!config.hideDot)}${(config.suffixValue || 0) || ''}`}
              </Typography>
            </Box>
            <Slider
              value={value ?? (config.startValue || config.minValue || 0)}
              valueLabelFormat={(sliderValue) => `${dotToNumber(sliderValue, !!config.hideDot)}${(config.suffixValue || 0) || ''}`}
              min={(config.minValue || 0)}
              max={(config.maxValue || 0)}
              marks={sliderMarks}
              step={(config.stepSize || 0)}
              sx={{
                '.MuiSlider-markLabel': {
                  opacity: 0
                },
                userSelect: 'none'
              }}
              onChange={(event, newValue) => {
                if (event.type === 'mouseup' || event.type === 'mousedown') {
                  return
                }

                onChange(newValue)
              }}
            />
          </Box>
        )}
      />
      <Typography
        sx={{
          position: 'absolute',
          bottom: '36px',
          left: 0
        }}
      >
        {`${dotToNumber(config.minValue, !!config.hideDot)}${config.suffixValue || ''}`}
      </Typography>
      <Typography
        sx={{
          position: 'absolute',
          bottom: '36px',
          right: 0
        }}
      >
        {`${dotToNumber(config.maxValue, !!config.hideDot)}${config.suffixValue || ''}`}
      </Typography>
    </Box>
  )
}
