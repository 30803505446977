import { Color, PaletteOptions, SimplePaletteColorOptions } from '@mui/material'

export type ThemePaletteOptions = PaletteOptions & {
  error: SimplePaletteColorOptions & Partial<Color>
  primary: SimplePaletteColorOptions & Partial<Color>
  secondary: SimplePaletteColorOptions & Partial<Color>
  warning: SimplePaletteColorOptions & Partial<Color>
  success: SimplePaletteColorOptions & Partial<Color>
  grey: Partial<Color>
}

export const palette: ThemePaletteOptions = {
  primary: {
    main: '#000000',
    light: '#000000',
    dark: '#000000',
    contrastText: '#F2F4F7',
    50: '#F2FBFF',
    100: '#e1f5ff',
    200: '#b4e5ff',
    300: '#81d5ff',
    400: '#4ec4ff',
    500: '#27b7ff',
    600: '#0BA8FF',
    700: '#109bef',
    800: '#1388db',
    900: '#1177c7',
    A100: '#1257a4'
  },
  grey: {
    50: '#FCFCFD',
    100: '#F9FAFB',
    200: '#F2F4F7',
    300: '#EAECF0',
    400: '#D0D5DD',
    500: '#98A2B3',
    600: '#667085',
    700: '#475467',
    800: '#344054',
    900: '#1D2939',
    A100: '#101828'
  },
  error: {
    main: '#F04438',
    light: '#F04438',
    dark: '#F04438',
    50: '#FFFBFA',
    100: '#FEF3F2',
    200: '#FEE4E2',
    300: '#FECDCA',
    400: '#FDA29B',
    500: '#F97066',
    600: '#F04438',
    700: '#D92D20',
    800: '#B42318',
    900: '#912018',
    A100: '#7A271A'
  },
  warning: {
    main: '#F79009',
    light: '#F79009',
    dark: '#F79009',
    50: '#FFFCF5',
    100: '#FFFAEB',
    200: '#FEF0C7',
    300: '#FEDF89',
    400: '#FEC84B',
    500: '#FDB022',
    600: '#F79009',
    700: '#DC6803',
    800: '#B54708',
    900: '#93370D',
    A100: '#7A2E0E'
  },
  success: {
    main: '#12B76A',
    light: '#12B76A',
    dark: '#12B76A',
    50: '#F6FEF9',
    100: '#ECFDF3',
    200: '#D1FADF',
    300: '#A6F4C5',
    400: '#6CE9A6',
    500: '#32D583',
    600: '#12B76A',
    700: '#039855',
    800: '#027A48',
    900: '#05603A',
    A100: '#054F31'
  },
  secondary: {
    main: '#717BBC',
    light: '#717BBC',
    dark: '#717BBC',
    50: '#FCFCFD',
    100: '#F8F9FC',
    200: '#EAECF5',
    300: '#D5D9EB',
    400: '#B3B8DB',
    500: '#717BBC',
    600: '#4E5BA6',
    700: '#3E4784',
    800: '#363F72',
    900: '#293056',
    A100: '#101323'
  },
  text: {
    primary: '#101828',
    secondary: '#F2F4F7'
  }
}
