import type React from 'react'
import {
  SvgIconComponent,
  Lock,
  Check
} from '@mui/icons-material'
import type { SvgIconProps } from '@mui/material'

import { LockOpen } from './icons/LockOpen'

export type ThemeIcons = Record<string, SvgIconComponent | React.FC<SvgIconProps>>

export const customThemeIcons = {
  Check,
  LockOpen,
  Lock
}

export const themeIcons = {
  ...customThemeIcons
}
