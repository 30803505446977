import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Question, useQuestionsContext } from '@providers/QuestionProvider'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAppSelector } from '@services/store/store'
import { themeIcons } from '@services/theme/icons'

type MappedQuestions = Question & {
  answered: boolean
  active: boolean
}

export const HomeView: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { answeredQuestions, name, participantId } = useAppSelector(({ app }) => app)

  const { questions } = useQuestionsContext()

  const handleQuestionClick = (questionKey: string) => {
    navigate(`/${questionKey}`)
  }

  const checkPreviousQuestionAnswered = (order: number) => {
    const question = questions.find(item => item.order === order)

    if (!question) {
      return true
    }

    return answeredQuestions?.includes(question.key)
  }

  const mappedQuestions = React.useMemo<MappedQuestions[]>(() => {
    return questions.map(question => {
      const active = checkPreviousQuestionAnswered(question.config.dependsOn)
      const answered = !answeredQuestions?.includes(question.key)

      return {
        ...question,
        active,
        answered
      }
    })
  }, [questions, answeredQuestions])

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
      overflowY: 'hidden',
      pb: 4
    }}
    >
      <Box sx={{
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      >
        <Typography sx={{
          lineHeight: '35px',
          fontSize: '28px'
        }}
        >{t('common.welcome')}</Typography>

        <Typography sx={{
          fontWeight: 'bold',
          lineHeight: '35px',
          fontSize: '28px',
          mb: 2
        }}
        >{t('common.kaleidosQuiz')}</Typography>

        <Typography mb={1}>{t('common.haveFun')}</Typography>
        <Typography mb={4}>{t('common.description')}</Typography>

        {mappedQuestions.map((question, index) => (
          <Box key={`question_${question.key}`}
            sx={{
              width: '100%',
              mb: 1.5
            }}
          >
            <Button
              variant='contained'
              fullWidth
              disabled={!question.answered || !question.active}
              onClick={() => handleQuestionClick(question.key)}
              endIcon={answeredQuestions?.includes(question.key)
                ? <themeIcons.Check />
                : question.active ? <themeIcons.LockOpen /> : <themeIcons.Lock />}
              sx={{
                justifyContent: 'space-between'
              }}
            >
              {t('common.questionIndex', { index: index + 1 })}
            </Button>
          </Box>
        ))}
      </Box>

      {name && participantId && (

      <Box sx={{
        position: 'fixed',
        bottom: '16px',
        left: '24px'
      }}
      >
        <Typography variant='subtitle1'>
          <Trans
            i18nKey='common.name'
            values={{
              name
            }}
            components={{
              b: <strong />
            }}
          />
        </Typography>
        <Typography variant='subtitle1'>
          <Trans
            i18nKey='common.participantId'
            values={{
              participantId
            }}
            components={{
              b: <strong />
            }}
          />
        </Typography>
      </Box>
      )}
    </Box>
  )
}
